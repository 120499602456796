<template>
  <div id="privacy">
    <TopList :navList="navList" :type="type" @setType="setType"></TopList>
    <div class="title">您可以在此处设置想要对外展示的信息</div>
    <div class="box">
      <div class="boxItem" v-for="item in list" :key="item.id">
        <span style="margin-right: 20px">{{ item.typeName }}</span>
        <i-switch
          :disabled="item.id == 1"
          v-model="item.flag"
          @on-change="change(item)"
        ></i-switch>
        <template v-if="item.childList">
          <div
            class="childListItem"
            v-for="childListItem in item.childList"
            :key="childListItem.id"
          >
            <span style="margin-right: 20px">{{ childListItem.typeName }}</span>
            <i-switch
              :disabled="childListItem.id == 2"
              size="small"
              v-model="childListItem.flag"
              @on-change="change(childListItem)"
            ></i-switch>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import { privacyList, setPrivacy } from "../../../../apis/user";

export default {
  name: "index",
  components: {
    TopList,
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
    async change(e) {
      console.log(e);
      let res = await setPrivacy({ id: e.id });
      if (res) {
        await this.getData();
      }
    },
    async getData() {
      let { data } = await privacyList();
      data.map((item) => {
        if (item.childList) {
          item.childList.map((obj) => (obj["flag"] = Boolean(obj.status)));
        }
        item["flag"] = Boolean(item.status);
      });
      console.log(data);
      this.list = data;
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      bol: false,
      type: 0,
      list: [],
      name: "",
      navList: ["隐私设置"],
    };
  },
};
</script>

<style lang="scss" scoped>
#privacy {
  min-height: 795px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  .title {
    margin-left: 40px;
    width: 238px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
    margin-top: 33px;
  }
  .box {
    margin-left: 40px;

    .boxItem {
      line-height: 70px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
      min-height: 70px;
      border-bottom: 1px dashed #e6e6e6;
      .childListItem {
        font-size: 14px;
        margin-left: 30px;
        line-height: 50px;
        height: 50px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
